<template>
  <Form @submit="save">
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h1>{{ this.uuid ? 'Editar' : 'Agregar' }} descuento</h1>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          ref="codeField"
          maxlength="15"
          label="Código *"
          prepend-icon="mdi-ticket"
          rules="required"
          :value="form.code"
          @input="form.code = $event.toUpperCase()"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.discount"
          label="Descuento (%) *"
          prepend-icon="mdi-percent"
          rules="required|min_value:1"
          type="number"
          min="1"
          max="100"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.start_date"
          label="Fecha inicio *"
          prepend-icon="mdi-calendar"
          rules="required"
          type="date"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.end_date"
          label="Fecha fin *"
          prepend-icon="mdi-calendar"
          rules="required"
          type="date"
        />
      </v-col>

      <v-col cols="12" class="text-center">
        <v-btn color="primary" type="submit">
          <v-icon left>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </Form>
</template>

<script>
// API
import DiscountAPI from '@/api/discount';

export default {
  name: 'DiscountForm',

  props: {
    uuid: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    form: {
      code: null,
      discount: null,
      start_date: null,
      end_date: null,
    },
  }),

  async created() {
    this.api = new DiscountAPI();

    if (this.uuid) {
      await this.detail();
    }
  },

  computed: {
    breadcrumbs() {
      const items = [
        {
          text: 'Descuentos',
          disabled: false,
          exact: true,
          to: { name: 'discount-list' },
        },
      ];

      if (this.uuid) {
        const name = this.form.code || '';
        items.push(
          {
            text: name.toUpperCase(),
            disabled: false,
            exact: true,
            to: { name: 'discount-detail', params: { uuid: this.uuid } },
          },
          {
            text: 'Editar',
            disabled: true,
          },
        );
      } else {
        items.push({
          text: 'Agregar',
          disabled: true,
        });
      }

      return items;
    },
  },

  methods: {
    async save() {
      this.$store.commit('showLoader');
      try {
        if (this.uuid) {
          await this.api.update(this.uuid, this.form);
          this.$router.push({ name: 'discount-detail', params: { uuid: this.uuid } });
        } else {
          await this.api.create(this.form);
          this.$router.push({ name: 'discount-list' });
        }

        this.$store.dispatch('notification/notifySuccess');
      } catch (error) {
        if (error.data?.non_field_errors?.includes('Código repetido.')) {
          this.$refs.codeField.setErrors('Código repetido.');
        }

        this.$store.dispatch('notification/notifyError', 'No se pudieron guardar los datos.');
      }
      this.$store.commit('hideLoader');
    },

    async detail() {
      this.$store.commit('showLoader');
      try {
        this.form = await this.api.detail(this.uuid);
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },
  },
};
</script>

<style lang="sass" scoped></style>
